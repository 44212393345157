import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';


const CustomImg = styled(Img)`
`;


const FluidImg = ({image, ...props}) => {
  if (!image || !image.localFile) return null;

  if (image.localFile.childImageSharp.sqip) {
    return (
      <CustomImg
        {...props}
        fluid={{
          ...image.localFile.childImageSharp.fluid,
          base64: image.localFile.childImageSharp.sqip.dataURI,
        }}
        placeholderStyle={{filter: 'blur(15px)', transform: 'scale(1.1)'}}
      />
    );
  }

  return (
    <CustomImg
      {...props}
      fluid={image.localFile.childImageSharp.fluid}
      placeholderStyle={{filter: 'blur(15px)', transform: 'scale(1.1)'}}
    />
  )
};

export default FluidImg;
