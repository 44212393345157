import React from 'react';
import styled, { css } from 'styled-components';


export const breakpoints = {
  xs: '414px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  ll: '1320px',
  hg: '1440px',
  hh: '1560px',
};

export const Section = styled.section`
`;

export const gridContentCell = css`minmax(25px, 138.75px)`;

export const grids = {
  xxs: css`
    display: grid;
    grid-template-columns: minmax(15px, 1fr) repeat(8, ${gridContentCell}) minmax(15px, 1fr);
    grid-column-gap: 8px;
  `,
  xs: css`
    display: grid;
    grid-template-columns: minmax(15px, 1fr) repeat(8, ${gridContentCell}) minmax(15px, 1fr);
    grid-column-gap: 12px;
  `,
  sm: css`
  `,
  md: css`
    display: grid;
    grid-template-columns: minmax(30px, 1fr) repeat(8, ${gridContentCell}) minmax(30px, 1fr);
    grid-column-gap: 30px;
  `,
  lg: css`
    display: grid;
    grid-template-columns: minmax(30px, 1fr) repeat(8, ${gridContentCell}) minmax(30px, 1fr);
    grid-column-gap: 30px;
  `,
};

export const gridCss = css`
  ${grids.xxs};
  @media(min-width: ${breakpoints.xs}) {
    ${grids.xs};
  }
  @media(min-width: ${breakpoints.sm}) {
  }
  @media(min-width: ${breakpoints.md}) {
    ${grids.md};
  }
  @media(min-width: ${breakpoints.lg}) {
    ${grids.lg};
  }
`;

export const Container = styled.div`
  ${gridCss};
  
  > * {
    position: relative;
  }
`;
