import React from 'react';
import NavBar from '../NavBar';
import styledNormalize from 'styled-normalize';
import styled, { createGlobalStyle } from 'styled-components';
import { fontFaces } from '../../styles/typography/index';
import { colors } from '../../styles/colors';
import { Helmet } from 'react-helmet';
import Footer from '../Footer';
import { graphql, StaticQuery } from 'gatsby';
import reset from 'styled-reset';
import routes, { sitemap } from '../../routes';
import { breakpoints } from '../../styles/layout';
import * as pluralize from 'pluralize';
import IEPopup from '../shared/IEPopup';


const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${reset}
  ${fontFaces}

  * {
    box-sizing: border-box;
    font-feature-settings: "pnum";
  }

  body {
    color: ${colors.darkBlue};
  }

  p {
    margin: 0;
  }

  a {
    color: inherit;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fade-in-up {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

`;

const FloatingNavBar = styled(NavBar)`
  position: sticky;
  z-index: 1030;
  top: 0;
  left: 0;
  width: 100%;
  @media(max-width: ${breakpoints.xl}) {
    position: fixed;
  }
`;

const Content = styled.div`
  overflow: hidden;
`;

const Layout = (props) => (
  <StaticQuery
    query={query}
    render={data => {
      const siteData = data.prismicSite.data;
      const rawData = JSON.parse(data.prismicSite.dataString);

      const initiatives = data.prismicServicesPage.data.initiatives.filter(initiative => initiative.service_page).map(initiative => ({
        label: initiative.title,
        path: `${routes.initiatives}/${initiative.service_page.uid}`,
      }));

      const categories = Array.from(new Set(data.allPrismicBlogPost.edges.map(edge => edge.node.data.category))).sort((a, b) => (a < b) ? -1 : 1);
      const filteredCategories = categories.filter(category => pluralize(category) !== 'Prayer Diaries');
      const postTypes = filteredCategories.map(category => ({
        label: pluralize(category),
        path: `${routes.journal}?category=${category}`,
      }));

      const resourceLinks = data.allPrismicStandalonePage.edges.filter(edge => edge.node.data.path === 'resources').map(edge => ({
        label: edge.node.data.title,
        path: `/${edge.node.data.path}/${edge.node.uid}`,
      }));

      const fullSitemap = sitemap.map(item => {
        if (item.path === routes.initiatives) {
          return {
            ...item,
            children: [
              ...item.children,
              ...initiatives,
            ],
          };
        }

        if (item.path === routes.positions) {
          return {
            ...item,
            children: [
              ...item.children,
            ],
          };
        }

        if (item.path === routes.journal) {
          return {
            ...item,
            children: [
              ...item.children,
              ...postTypes,
            ],
          };
        }

        if (item.label === 'Resources') {
          return {
            ...item,
            children: [
              ...item.children,
              ...resourceLinks,
            ]
          }
        }

        return item;
      });

      const footerSitemap = sitemap.map(item => {
        if (item.path === routes.journal) {
          return {
            ...item,
            children: [
              ...item.children,
              ...postTypes,
            ],
          };
        }

        if (item.path === routes.impact) {
          return {
            ...item,
            children: [
              ...item.children,
              ...data.prismicImpactPage.data.methods.map(method => ({
                label: method.title,
                path: method.button_link,
              })),
            ],
          };
        }

        if (item.label === 'Resources') {
          return {
            ...item,
            children: [
              ...item.children,
              ...resourceLinks,
            ]
          }
        }

        return item;
      });

      return (
        <div>
          <Helmet>
            <meta property="og:type" content="website"/>
          </Helmet>
          <GlobalStyle/>
          <FloatingNavBar
            logo={siteData.logo.url}
            logoReverse={siteData.logo_reverse.url}
            socialLinks={siteData.social_links}
            contactEmail={siteData.contact_email}
            contactPhone={siteData.contact_phone}
            siteName={siteData.name}
            reverseLogo={props.reverseLogo}
            initiatives={initiatives}
            postTypes={postTypes}
            sitemap={fullSitemap}
            mobileSitemap={footerSitemap}
          />
          <Content>
            {props.children}
          </Content>
          <IEPopup/>
          <Footer
            siteData={siteData}
            rawData={rawData}
            sitemap={footerSitemap}
          />
        </div>
      );
    }}
  />
);

export default Layout;

const query = graphql`
  query SiteTitleQuery {
    prismicSite {
      dataString
      data {
        name
        legal_details {
          html
        }
        logo {
          url
        }
        logo_with_tagline {
          url
        }
        logo_reverse {
          url
        }
        social_links {
          url
          icon {
            url
          }
          icon_reverse {
            url
          }
        }
        contact_email
        contact_phone
        accreditations {
          logo1 {
            url
          }
          url
        }
      }
    }
    prismicServicesPage {
      data {
        initiatives {
          title
          service_page {
            uid
          }
        }
      }
    }
    allPrismicBlogPost {
      edges {
        node {
          data {
            category
          }
        }
      }
    }
    prismicImpactPage {
      data {
        methods {
          title
          button_text
          button_link
        }
      }
    }
    allPrismicStandalonePage {
      edges {
        node {
          uid
          data {
            title
            path
          }
        }
      }
    }
  }
`;
