import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../styles/layout';
import { typeN14r, typeN16r } from '../styles/typography/presets';
import { Field } from 'formik';
import { colors } from '../styles/colors';


export const TextField = styled(Field)`
  ${typeN14r};
  border: none;
  outline: none;
  background: transparent;
  border-bottom: 1px solid ${colors.darkBlue};
  padding: 12px 0;
  
  &::placeholder {
    color: ${colors.darkBlue};
  }
  
  @media(min-width: ${breakpoints.lg}) {
    ${typeN16r};
  }
`;
