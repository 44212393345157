
export const colors = {
  white: '#ffffff',
  orange: '#f37021',
  yellow: '#faa61a',
  purple: '#6f2c91',
  pink: '#a3238e',
  beige: '#f8f9f9',
  pinkishGrey: '#ecc7cd',
  orangishGrey: '#eee9e3',
  grey: '#bfb8af',
  darkBlue: '#100149',
  black: '#000000',
};