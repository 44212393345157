import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../styles/colors';
import * as presets from '../../styles/typography/presets';
import { Link } from 'gatsby';
import { breakpoints } from '../../styles/layout';


export const buttonBase = css`
  display: inline-block;
  background: none;
  border: none;
  -webkit-appearance: none;
  box-shadow: none;
  text-decoration: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  word-break: keep-all;
  
  &:disabled {
    cursor: not-allowed;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const roundButtons = css`
  &:hover:disabled,
  &:disabled {
    background: ${colors.grey};
    color: ${colors.white};
    border-color: ${colors.grey};
  }
`;

const coloredButtonStyle = css`
  line-height: 1em;
  padding: 10px 40px;
  border-radius: 100px;
  text-align: center;
  transition: all 200ms ease-out;
  ${presets.typeN14ru};
  
  @media(min-width: ${breakpoints.lg}) {
    ${presets.typeN16ru};
  }
  
  &:hover {
    background: transparent;
    border-color: ${colors.white};
    color: ${colors.white};
    a {
      color: ${colors.white};
    }
  }
`;

const WhiteButtonStyle = css`
  ${buttonBase};
  ${roundButtons};
  ${coloredButtonStyle};
  border: 1px solid ${colors.white};
  background: ${colors.white};
  color: ${colors.purple};
`;

export const WhiteButton = styled.button`
  ${WhiteButtonStyle};
  a {
    text-decoration: none;
    color: ${colors.purple};
  }
`;

export const WhiteButtonLink = styled(Link)`
  ${WhiteButtonStyle};
`;

export const OutlineButtonStyle = css`
  ${buttonBase};
  ${roundButtons};
  ${presets.typeN14ru};
  border: 1px solid ${colors.white};
  border-radius: 100px;
  padding: 10px 30px;
  line-height: 1em;
  text-align: center;
  
  @media(min-width: ${breakpoints.lg}) {
    ${presets.typeN16ru};
    line-height: 1em;
    padding: 10px 30px;
  }
`;

const WhiteOutlineButtonStyle = css`
  ${OutlineButtonStyle};
  color: ${colors.white};
  border-color: ${colors.white};
  transition: all 200ms ease-out;
  
  &:hover {
    background: ${colors.white};
    color: ${colors.orange};
    border-color: ${colors.white};
  }
`;

export const WhiteOutlineButton = styled.button`
  ${WhiteOutlineButtonStyle};
`;

export const WhiteOutlineButtonLink = styled(Link)`
  ${WhiteOutlineButtonStyle};
`;

export const PurpleOutlineButtonStyle = css`
  ${OutlineButtonStyle};
  color: ${colors.purple};
  border-color: ${colors.purple};
  transition: all 200ms ease-out;
  
  &:hover {
    background: ${colors.pink};
    color: ${colors.white};
    border-color: ${colors.white};
  }
  
  &:disabled {
    background: ${colors.grey};
    color: ${colors.white};
    border-color: ${colors.grey};
  }
`;

export const PurpleOutlineButton = styled.button`
  ${PurpleOutlineButtonStyle};
`;

export const PurpleOutlineButtonLink = styled(Link)`
  ${PurpleOutlineButtonStyle};
`;

const DarkBlueOutlineButtonStyle = css`
  ${OutlineButtonStyle};
  color: ${colors.darkBlue};
  border-color: ${colors.darkBlue};
  transition: all 200ms ease-out;
  
  &:hover {
    background: ${colors.purple};
    color: ${colors.white};
    border-color: ${colors.white};
  }
`;

export const DarkBlueOutlineButton = styled.button`
  ${DarkBlueOutlineButtonStyle}
`;

export const DarkBlueOutlineButtonLink = styled(Link)`
  ${DarkBlueOutlineButtonStyle}
`;

export const PurpleButtonStyle = css`
  ${buttonBase};
  ${roundButtons};
  ${coloredButtonStyle};
  border: 1px solid ${colors.purple};
  background: ${colors.purple};
  color: ${colors.white};
  
  &:hover {
    color: ${colors.purple};
    background: ${colors.white};
    border: 1px solid ${colors.purple};
  }
`;

export const OrangeButtonStyle = css`
  ${buttonBase};
  ${roundButtons};
  ${coloredButtonStyle};
  border: 1px solid ${colors.orange};
  background: ${colors.orange};
  color: ${colors.white};
  
  &:hover {
    color: ${colors.orange};
    background: ${colors.white};
    border: 1px solid ${colors.orange};
  }
`;
