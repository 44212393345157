const routes = {
  home: "/",
  about: "/about",
  initiatives: "/initiatives",
  journal: "/journal",
  contact: "/contact",
  korusLounge: "/korus-lounge",
  privacyPolicy: "/privacy-policy",
  childSafePolicy: "/child-safe-policy",
  donate: "/donate",
  principles: "/principles",
  impact: "/impact",
  positions: "/positions",
  collaborate: "/collaborate",
  jobRequirements: "/work",
  // volunteer: "/volunteer",
};


export const sitemap = [
  {
    label: 'Who We Are',
    tagline: 'Learn more about us.',
    path: routes.about,
    children: [
      {
        label: 'Our Story',
        path: routes.about,
      },
      {
        label: 'Our Beliefs',
        path: routes.about + "#beliefs",
      },
      {
        label: 'Purpose, Vision & Mission',
        path: routes.about + "#purpose",
      },
      {
        label: 'Our Values',
        path: routes.about + "#values",
      },
      {
        label: 'Commitment to Child Safety',
        path: routes.about + "#about-children",
      },
    ]
  },
  {
    label: 'What We Do',
    tagline: 'Facilitating lasting change.',
    path: routes.initiatives,
    children: [
      {
        label: 'All Initiatives',
        path: routes.initiatives,
      },
      {
        label: "Underpinning Principles",
        path: routes.principles,
      },
    ]
  },
  {
    label: 'Work with us',
    tagline: 'How you can be a catalyst for change.',
    path: routes.positions,
    children: [
      {
        label: 'Job Openings',
        path: routes.positions,
      },
      {
        label: "Job Requirements",
        path: routes.jobRequirements,
      },
      {
        label: "Collaborate",
        path: routes.collaborate,
      },
      // {
      //   label: "Volunteer Opportunities",
      //   path: routes.volunteer,
      // },
    ]
  },
  {
    label: 'Journal',
    tagline: 'Stories and insights to inspire.',
    path: routes.journal,
    children: [
      {
        label: 'All Posts',
        path: routes.journal,
      },
    ]
  },
  {
    label: 'Resources',
    tagline: 'Equipping the community.',
    children: [
      {
        label: 'Korus Lounge',
        path: routes.korusLounge
      }
    ]
  },
  {
    label: 'Get in touch',
    path: routes.contact,
    children: [
      {
        label: 'Speak with us',
        path: routes.contact,
      },
      {
        label: 'Privacy Policy',
        path: routes.privacyPolicy,
      },
      {
        label: 'Child Safe Policy',
        path: routes.childSafePolicy,
      },
    ]
  },
];


export default routes;
