import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { rtypeN18r, rtypeT38sb } from '../../styles/typography/presets';
import { rgba } from 'polished';
import { PurpleOutlineButton } from '../buttons';
import bowser from 'bowser';

const Title = styled.div`
  ${rtypeT38sb};
`;

const Brief = styled.div`
  ${rtypeN18r};
`;

const Button = styled(PurpleOutlineButton)`

`;

const Content = styled.div`
  background: ${colors.white};
  border-radius: 6px;
  padding: 40px 40px;
  
  ${Title} {
    margin-bottom: 0.5em;
  }
  
  ${Button} {
    margin-top: 30px;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba(colors.black, 0.5)};
`;

const Container = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  
  ${Content} {
    width: 100%;
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};
  }
`;


export default class IEPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.browser = bowser.getParser(window.navigator.userAgent);
    console.log(`The current browser name is "${this.browser.getBrowserName()}"`);
    if(this.browser.getBrowserName() === 'Internet Explorer') {
      this.setState({show: true})
    }
  }

  render() {
    const {...props} = this.props;

    if (this.state.show) {
      return (
        <Container {...props}>
          <Background onClick={() => this.setState({show: false})}/>
          <Content>
            <Title>Your browser is not supported</Title>
            <Brief>
              <p>
                This website supports standards-compliant browsers such as Chrome, Edge, Safari, Firefox, Opera.
                Please download a secure browser.
              </p>
            </Brief>
            <Button>
              <a href={'https://www.google.com/chrome/'} target={'_blank'} rel={'noopener noreferrer'}>
                Download
              </a>
            </Button>
          </Content>
        </Container>
      )
    }

    return null;
  }
}
