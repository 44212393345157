import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../styles/layout';
import { Link } from 'gatsby';
import EmailForm from './shared/EmailForm';
import * as presets from '../styles/typography/presets';
import { typeN12r, typeN14r, typeN16r, typeT16sb } from '../styles/typography/presets';
import { colors } from '../styles/colors';
import routes from '../routes';
import { webhooks } from '../webhooks';
import RawHTML from './shared/RawHTML';


const Logo = styled.div`

`;

const Separator = styled.div`
  height: 1px;
  background: ${colors.darkBlue};
`;

const Country = styled.div`
`;

const CountryParagraph = styled.p`
  ${typeN16r};
`;

const Header = styled.div`
  ${typeT16sb};
  color: ${colors.darkBlue};
  text-decoration: none;
`;

const NavItem = styled.li`
  ${typeN16r};
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavGroup = styled.div`
`;

const Sitemap = styled.div`
`;

const EmailFormContainer = styled.div`

`;

const SocialLink = styled.li`

`;

const SocialLinks = styled.ul`
  list-style: none;
  margin: 0 -15px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  ${SocialLink} {
    padding: 0 15px 24px;
  }
`;

const CustomNativeLink = styled.a`
  ${presets.typeN12r};
  text-decoration: none;
  display: block;
  color: ${colors.darkBlue};
`;

const ContactInfo = styled.div``;

const LegalName = styled(RawHTML)`
  ${typeN14r};
`;

const Accreditation = styled.li`

`;

const Accreditations = styled.ul`
  list-style: none;
  padding: 0;
  margin: -15px;
  display: flex;

  ${Accreditation} {
    padding: 15px;
    img {
      height: 80px;
      width: auto;
    }
  }
`;

const Main = styled(Container)`
  background: ${colors.orangishGrey};
  padding: 60px 0;

  ${Header} {
    margin-bottom: 12px;
  }

  ${Logo} {
    grid-column: 2 / span 8;
    margin-bottom: 31px;
    img {
      height: 64px;
      max-width: 100%;
    }
  }

  ${Separator} {
    grid-column: 2 / span 8;
    margin-bottom: 42px;
  }

  ${Country} {
    grid-column: 2 / span 8;
    margin-bottom: 42px;
  }

  ${Sitemap} {
    grid-column: 2 / span 8;
    margin-bottom: 63px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 33px;
    grid-column-gap: 10px;
  }

  ${EmailFormContainer} {
    grid-column: 2 / span 8;
    margin-bottom: 30px;
  }

  ${SocialLinks} {
    grid-column: 2 / span 8;
    margin-bottom: 30px;
  }

  ${ContactInfo} {
    grid-column: 2 / span 8;
    margin-bottom: 50px;
    ${CustomNativeLink} {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${LegalName} {
    grid-column: 2 / span 8;
    margin-bottom: 21px;
  }

  ${Accreditations} {
    grid-column: 2 / span 8;
  }

  @media(min-width: ${breakpoints.lg}) {
    padding: 90px 0 30px;
    grid-template-rows: auto auto max-content min-content auto;
    ${Separator} {
      margin-bottom: 90px;
      grid-row: 2 / span 1;
    }
    ${Country} {
      margin: 40px 0 64px;
      grid-row: 2 / span 1;
    }

    ${Sitemap} {
      grid-column: 2 / span 4;
      grid-row: 3 / span 2;
      margin-bottom: 60px;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 45px;
      grid-column-gap: 15px;
    }

    ${EmailFormContainer} {
      grid-column: 7 / span 3;
      grid-row: 3 / span 1;
    }

    ${ContactInfo} {
      grid-column: 7 / span 2;
      grid-row: 4 / span 1;

      ${CustomNativeLink} {
        ${typeN16r};
      }
    }

    ${SocialLinks} {
      grid-column: 9 / span 1;
      grid-row: 4 / span 1;
    }

    ${LegalName} {
      ${typeN16r};
      grid-column: 2 / span 5;
      grid-row: 5;
      align-self: center;
      margin-bottom: 0;
    }

    ${Accreditations} {
      grid-column: 6 / span 4;
      grid-row: 5;
      align-self: center;
      justify-self: flex-end;
      margin-bottom: 0;
    }
  }
`;

const Rights = styled.div`
  ${typeN12r};
  color: ${colors.grey};
`;

const Credits = styled.div`
  ${typeN12r};
  color: ${colors.grey};

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Bar = styled(Container)`
  background: ${colors.white};
  padding: 27px 0;

  ${Rights} {
    grid-column: 2 / span 4;
  }

  ${Credits} {
    grid-column: 7 / span 3;
    text-align: right;
  }

  @media(min-width: ${breakpoints.lg}) {
    padding: 33px 0;

    ${Rights} {
      ${typeN16r}
    }

    ${Credits} {
      ${typeN16r}
    }
  }
`;

const CustomContainer = styled.div`
  overflow: hidden;
`;

const Footer = ({ siteData, sitemap, hideContent, ...props }) => (
  <CustomContainer>
    <Main>
      <Logo>
        {hideContent ? (
          <img src={siteData.logo_with_tagline.url} alt={props.rawData.logo_with_tagline.alt}/>
        ) : (
          <Link to={routes.home}>
            <img src={siteData.logo_with_tagline.url} alt={props.rawData.logo_with_tagline.alt}/>
          </Link>
        )}
      </Logo>
      <Separator/>
      <Country>
        <Header><strong>Acknowledgement of First Nations</strong></Header>
        <CountryParagraph>As we gather in the presence of our creator God, Korus Connect acknowledges the traditional custodians of the land upon which we meet. In His wisdom and love, our Heavenly Father entrusted this land to the stewardship of the Wurundjeri Woi-wurrung people. As we continue to learn to live together, we acknowledge and pay our respects to their elders, past, present and emerging, and pray that God would help us all to preserve and steward these resources well.</CountryParagraph>
      </Country>
      {!hideContent && (
        <>
      <Sitemap>
        {sitemap.map(route => (
          <NavGroup key={route.label}>
            <Header>
              {route.label}
            </Header>
            <NavList>
              {route.children && route.children.map(route => (
                <NavItem key={route.label}><Link to={route.path}
                                                 onClick={props.linkClicked}>{route.label}</Link></NavItem>
              ))}
            </NavList>
          </NavGroup>
        ))}
      </Sitemap>
      <EmailFormContainer>
        <Header>Connect to Pray</Header>
        <EmailForm
          webhook={webhooks.prayerDiary}
          footnote={'Get a monthly Prayer Diary from Korus Connect'}
        />
      </EmailFormContainer>
      <SocialLinks>
        {props.rawData.social_links.map((socialLink, index) => (
          <SocialLink key={index}>
            <a href={socialLink.url} target={'_blank'} rel="noopener noreferrer">
              <img src={socialLink.icon.url} alt={socialLink.icon.alt}/>
            </a>
          </SocialLink>
        ))}
      </SocialLinks>
      <ContactInfo>
        <CustomNativeLink href={`mailto:${siteData.contact_email}`} target={'_blank'} rel="noopener noreferrer">
          {siteData.contact_email}
        </CustomNativeLink>
        <CustomNativeLink href={`tel:${siteData.contact_phone}`} target={'_blank'} rel="noopener noreferrer">
          {siteData.contact_phone}
        </CustomNativeLink>
      </ContactInfo>
        </>
      )}
      <LegalName>
        {siteData.legal_details.html}
      </LegalName>
      <Accreditations>
        {props.rawData.accreditations.map((accreditation, index) => (
          <Accreditation key={index}>
            <a
              href={accreditation.url}
              target={'_blank'} rel={'noopener noreferrer'}>
              <img src={accreditation.logo1.url} alt={accreditation.logo1.alt}/>
            </a>
          </Accreditation>
        ))}
      </Accreditations>
    </Main>
    <Bar>
      <Rights>
        &copy; {`${siteData.name} ${(new Date()).getFullYear()}`}. All rights reserved.
      </Rights>
      {!hideContent && (
        <Credits>
          <a href="https://mammoth.tech" target="_blank">
            Made by Mammoths
          </a>
        </Credits>
      )}
    </Bar>
  </CustomContainer>
);


export default Footer;
