import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { Link } from 'gatsby';
import * as presets from '../../styles/typography/presets';
import { Container } from '../../styles/layout';
import { WhiteOutlineButtonLink } from '../buttons';
import routes from '../../routes';

const LogoContainer = styled(Container)`
  padding: 24px 0 42px;
  a {
    grid-column: 2 / span 4;
    img {
      height: 36px;
    }
  }
`;

const ContactInfo = styled.div``;

const Header = styled.div`
  ${presets.typeT16sb};
  color: ${colors.white};
  padding: 12px 0 15px;
  border-top: 1px solid ${colors.white};
  
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const SocialLink = styled.li`

`;

const SocialLinks = styled.ul`
  list-style: none;
  margin: 32px -15px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  
  ${SocialLink} {
    padding: 0 15px 24px;
  }
`;

const Rights = styled.div`
  ${presets.typeN12r};
  color: ${colors.white};
`;

const Credits = styled.div`
  a {
    ${presets.typeN12r};
    color: ${colors.white};
    text-decoration: none;
  }
`;

const CustomLink = styled(Link)`
  ${presets.typeN16r};
  color: ${colors.white};
  text-decoration: none;
`;

const CustomNativeLink = styled.a`
  ${presets.typeN12r};
  color: ${colors.white};
  text-decoration: none;
  display: block;
`;

const NavItem = styled.li``;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  
  ${NavItem} {
    margin-bottom: 9px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const NavGroup = styled.div``;

const Nav = styled.nav`
  background: linear-gradient(to top right, ${colors.pink}, ${colors.purple});
`;

const MobileNavMenuContainer = styled(Container)`
  padding-bottom: 90px;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 30px;
  
  ${NavGroup}:nth-child(2n+1) {
    grid-column: 2 / span 3;
  }
  
  ${NavGroup}:nth-child(2n+2) {
    grid-column: 6 / span 3;
  }
  
  ${NavGroup}:last-child {
    grid-column: 6 / span 4;
    ${ContactInfo} {
      margin-bottom: 31px;
    }
  }
`;

const ButtonLink = styled(WhiteOutlineButtonLink)`
  margin-bottom: 8px;
  margin-right: 8px;
`;


const MobileNavMenu = ({sitemap, ...props}) => {
  return (
    <Nav className={props.className}>
      <LogoContainer>
        <Link to={routes.home} onClick={props.linkClicked}>
          <img src={props.logo}/>
        </Link>
      </LogoContainer>
      <MobileNavMenuContainer>
        {sitemap.map(route => (
          <NavGroup key={route.label}>
            <Header>
              {route.label}
            </Header>
            <NavList>
              {route.children && route.children.map(route => (
                <NavItem key={route.label}>
                  <CustomLink
                    to={route.path}
                    onClick={props.linkClicked}
                  >
                    {route.label}
                  </CustomLink>
                </NavItem>
              ))}
            </NavList>
          </NavGroup>
        ))}
        <NavGroup>
          <ButtonLink to={routes.donate}>Donate</ButtonLink>
          <ButtonLink to={routes.contact}>Contact</ButtonLink>
          <SocialLinks>
            {props.socialLinks.map((socialLink, index) => (
              <SocialLink key={index}>
                <a href={socialLink.url}>
                  <img src={socialLink.icon_reverse.url}/>
                </a>
              </SocialLink>
            ))}
          </SocialLinks>
          <ContactInfo>
            <CustomNativeLink href={`mailto:${props.contactEmail}`} target={'_blank'}>
              {props.contactEmail}
            </CustomNativeLink>
            <CustomNativeLink href={`tel:${props.contactPhone}`} target={'_blank'}>
              {props.contactPhone}
            </CustomNativeLink>
          </ContactInfo>
          <Rights>
            &copy; {`${props.siteName} ${(new Date()).getFullYear()}`}<br/>
            All rights reserved
          </Rights>
          <Credits>
            <a href="https://mammoth.tech" target="_blank">
              By Mammoth
            </a>
          </Credits>
        </NavGroup>
      </MobileNavMenuContainer>
    </Nav>
  );
};


export default MobileNavMenu;
