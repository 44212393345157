import React from 'react';
import { colors } from '../../styles/colors';
import styled from 'styled-components';


const Line = styled.div`
  background: ${colors.darkBlue};
  width: 100%;
  height: 2px;
`;

const NavMenuContainer = styled.div`
  outline: none;
  width: 60px;
  height: 60px;
  background: none;
  cursor: pointer;
  
  ${Line} {
    background: ${props => props.open ? colors.white : colors.darkBlue};
  }
  
  -webkit-appearance: none;
  border: none;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px 15px;
`;


const NavMenuButton = (props) => {
  return (
    <NavMenuContainer {...props}>
      <Line/>
      <Line/>
      <Line/>
    </NavMenuContainer>
  );
};


export default NavMenuButton;
