import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import routes from '../routes';
import { breakpoints, Container } from '../styles/layout';
import * as presets from '../styles/typography/presets';
import { typeT21sb } from '../styles/typography/presets';
import { colors } from '../styles/colors';
import NavMenuButton from './shared/NavMenuButton';
import MobileNavMenu from './shared/MobileNavMenu';
import { rgba } from 'polished';
import { PurpleOutlineButtonLink } from './buttons';
import { fonts } from '../styles/typography';
import { rtypeN16r } from '../styles/typography/presets';
import { typeN16b } from '../styles/typography/presets';


const LogoImg = styled.img`
`;

const LogoImgReverse = styled.img`

`;

const Logo = styled.div`
  height: 30px;
  position: relative;
  
  ${LogoImg}, ${LogoImgReverse} {
    position: absolute;
    height: 100%;
    width: auto;
    transition: all 500ms ease-out;
  }
`;

const LogoContainer = styled.div``;

const TopLevelStyle = css`
  ${typeN16b};
  color: ${colors.purple};
  text-decoration: none;
  transition: all 500ms ease-out;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: ${props => props.open ? colors.pink : 'transparent'};
  color: ${props => props.open ? colors.white : colors.purple};
  padding: 12px 20px;
  
  @keyframes expand-width {
    from {
      width: 0%;
    }
    to { 
      width: 100%;
    }
  }
  
  span {
    padding: 4px 0;
    position: relative;
    
    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: ${colors.purple};
      transition: all 500ms ease-out;
    }
  }
  
  &.active span:after {
    animation: expand-width 500ms ease-out;
  }
  
  &.active span:after,
  &:hover span:after {
    width: 100%;
  }
  
  &:hover span:after {
    background: ${colors.white};
  }
`;

const TopLevelLink = styled(Link)`
  ${TopLevelStyle};
`;

const TopLevelParent = styled.div`
  ${TopLevelStyle};
  cursor: default;
`;

const ButtonLink = styled(PurpleOutlineButtonLink)``;

const NavSubMenuTitle = styled.div`
  ${typeT21sb};
  color: ${colors.white};
`;

const NavSubItemLink = styled(Link)`
  ${presets.typeN16b};
  color: ${colors.purple};
  text-decoration: none;
  display: block;
`;

const NavSubItem = styled.li`
  border-bottom: 1px solid ${colors.white};
  padding: 9px 0;
  
  &:first-child,
  &:nth-child(2) {
    border-top: 1px solid ${colors.white};
  }
  
  a {
    color: ${colors.white};
    &:before {
      content: '→ ';
      font-family: ${fonts.national.family};
    }
  }
`;

const NavSubItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  
  ${NavSubItem} {
    &:nth-child(2n+1) {
      grid-column: 1;
    }
    &:nth-child(2n+2) {
      grid-column: 2;
    }
  }
`;

const NavSubMenu = styled.div`
  background: ${colors.pink};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 60px 100px 60px 60px;
  
  display: grid;
  grid-template-columns: 240px 570px;
  grid-column-gap: 60px;
  align-items: flex-start;
  
  ${NavSubMenuTitle} {
    grid-column: 1;
  }
  
  ${NavSubItems} {
    grid-column: 2;
  }
`;

const NavItem = styled.li`
  ${NavSubMenu} {
    transition: all 500ms ease-out;
    opacity: ${props => props.open ? 1 : 0};
    visibility: ${props => props.open ? 'visible' : 'hidden'};
  }
`;

const NavItemButton = styled.li`

`;

const NavItems = styled.ul`
`;

const DesktopNavMenu = styled.nav`
  height: 100%;
`;

const MobileNavMenuButton = styled(NavMenuButton)``;

const CustomMobileNavMenu = styled(MobileNavMenu)`
  transition: all 500ms ease-out;
  visibility: ${props => props.open ? 'visible' : 'hidden'};
  opacity: ${props => props.open ? '1' : '0'};
`;

const NavBarBackground = styled.div``;

const NavBarContainer = styled(Container)`
  align-items: center;
  transition: all 500ms ease-out;
  height: 60px;
  
  ${Logo} {
    ${LogoImg} {
      visibility: ${props => props.reverseLogo && !props.hover ? 'hidden' : 'visible'};
      opacity: ${props => props.reverseLogo && !props.hover ? 0 : 1};
    }
    ${LogoImgReverse} {
      visibility: ${props => props.reverseLogo && !props.hover ? 'visible' : 'hidden'};
      opacity: ${props => props.reverseLogo && !props.hover ? 1 : 0};
    }
  }
  
  ${NavBarBackground} {
    transition: all 500ms ease-out;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    height: 100%;
    width: ${props => props.hover ? '100%' : '60px'};
    ${props => (!props.hover && props.hideContent) && `width: 0;`};
    border-bottom-left-radius: ${props => props.hover ? '0' : '6px'};
    box-shadow: 0 0 6px ${rgba(colors.darkBlue, 0.15)};
    background: ${props => (!props.open || props.hover) ? colors.white : 'transparent'};
  }

  ${LogoContainer} {
    grid-column: 2 / span 4;
    transition: all 500ms ease-out;
    margin-top: ${props => props.shrink ? '0' : '24px'};
  }

  ${DesktopNavMenu} {
    display: none;
  }
  
  ${CustomMobileNavMenu} {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  ${MobileNavMenuButton} {
    position: fixed;
    z-index: 1040;
    top: 0;
    right: 0;
  }
  
  @media (min-width: ${breakpoints.md}) {
    ${Logo} {
      transition: all 500ms ease-out;
      height: 30px;
    }
  }
  
  @media (min-width: ${breakpoints.ll}) {
    height: ${props => props.shrink ? '90px' : '100px'};
    
    ${Logo} {
      ${LogoImg} {
        display: block;
        visibility: visible;
        opacity: 1;
      }
      ${LogoImgReverse} {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }
    
    ${NavBarBackground} {
      grid-column: 1 / span 10;
      // width: ${props => props.hover ? `${props.fullWidth}px` : `${props.mountedBackgroundWidth}px`};
      border-radius: 0;
      width: 100%;
      background: white;
    }
    
    ${LogoContainer} {
      grid-column: 1 / span 2;
      padding-left: 60px;
      align-self: center;
      margin: 0;
      ${Logo} {
        transition: all 500ms ease-out;
        height: ${props => props.shrink ? '45px' : '55px'};
      }
    }
    ${DesktopNavMenu} {
      display: block;
      grid-column: 4 / span 7;
      position: relative;
      z-index: 1;
      
      ${NavSubMenu} {
        position: absolute;
        z-index: -1;
        top: 100%;
        //left: 50%;
        right: 0;
        //transform: translateX(-50%);
        box-shadow: 0 0 6px ${rgba(colors.darkBlue, 0.15)};
      }
      ${NavItems} {
        list-style: none;
        margin: 0;
        padding: 0 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        
        ${NavItem} {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          
          ${TopLevelParent},
          ${TopLevelLink} {
            align-self: flex-end;
            height: 72px;
            padding: 12px 14px;
          }
        }
        
        ${NavItemButton} {
          margin-left: 20px;
          display: flex;
          align-items: center;
        }
      }
    }
    ${MobileNavMenuButton} {
      display: none;
    }
    ${CustomMobileNavMenu} {
      display: none;
    }
  }
  
  @media(min-width: ${breakpoints.hg}) {
    ${DesktopNavMenu} {
      ${NavItems} {
        ${NavItem} {
          ${TopLevelParent},
          ${TopLevelLink} {
            padding: 12px 20px;
          }
        }
      }
    }
  }
`;

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNavMenuOpen: false,
      shrink: false,
      reverse: false,
      currentDropdown: null,
    };
    this.desktopNavMenuRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateNavBar);
    this.updateNavBar();
    window.addEventListener('resize', this.updateMountedWidth);
    this.updateMountedWidth();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateNavBar);
    window.removeEventListener('resize', this.updateMountedWidth);
  }

  updateNavBar = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

    if (scrollTop !== 0) {
      this.setState({
        hover: true,
        // shrink: true,
      });
    } else {
      this.setState({
        hover: false,
        // shrink: false,
      });
    }
  };

  updateMountedWidth = () => {
    if (!this.desktopNavMenuRef.current) return;
    const mountedWidth = this.desktopNavMenuRef.current.clientWidth;
    const fullWidth = window.innerWidth;
    this.setState({
      mountedWidth: mountedWidth,
      fullWidth: fullWidth,
    });
  };

  toggleMobileNavMenu = () => {
    this.setState((prevState) => ({
      mobileNavMenuOpen: !prevState.mobileNavMenuOpen,
    }));
  };

  closeDesktopDropdown = () => {
    this.setState({
      currentDropdown: null,
    });
  };

  render() {
    const {
      logo,
      logoReverse,
      reverseLogo,
      className,
      socialLinks,
      contactEmail,
      contactPhone,
      siteName,
      sitemap,
      mobileSitemap,
      hideContent,
    } = this.props;

    return (
      <NavBarContainer
        fullWidth={this.state.fullWidth}
        mountedBackgroundWidth={this.state.mountedWidth}
        className={className}
        open={this.state.mobileNavMenuOpen}
        shrink={this.state.shrink}
        hover={this.state.hover}
        reverseLogo={reverseLogo}
        hideContent
      >
        <LogoContainer>
          {hideContent ? (
            <Logo>
              <LogoImg src={logo}/>
              <LogoImgReverse src={logoReverse}/>
            </Logo>
          ) : (
            <Link to={routes.home}>
              {/*{reverseLogo && !this.state.hover ? <Logo src={logoReverse}/> : <Logo src={logo}/>}*/}
              <Logo>
                <LogoImg src={logo}/>
                <LogoImgReverse src={logoReverse}/>
              </Logo>
            </Link>
          )}
        </LogoContainer>

        {!hideContent && (
          <React.Fragment>
            <DesktopNavMenu
              innerRef={this.desktopNavMenuRef}
              onMouseLeave={() => this.setState({ currentDropdown: null })}>
              <NavItems>
                {sitemap.map(route => {
                  if (route.path === routes.contact) return;
                  return (
                    <NavItem key={route.label}
                             open={this.state.currentDropdown === route.label}
                             onClick={() => this.setState((prevState) => ({
                               currentDropdown: prevState.currentDropdown === route.label ? null : route.label,
                             }))}
                    >
                      {route.path ? (
                        <TopLevelLink
                          onMouseEnter={() => this.setState({ currentDropdown: route.label })}
                          open={this.state.currentDropdown === route.label}
                          to={route.path}
                          activeClassName={'active'}
                        >
                          <span>{route.label}</span>
                        </TopLevelLink>
                      ) : (
                        <TopLevelParent
                          onMouseEnter={() => this.setState({ currentDropdown: route.label })}
                          open={this.state.currentDropdown === route.label}
                        >
                          {route.label}
                        </TopLevelParent>
                      )}
                      <NavSubMenu>
                        <NavSubMenuTitle>{route.tagline}</NavSubMenuTitle>
                        <NavSubItems>
                          {route.children && route.children.map(childRoute => (
                            <NavSubItem key={childRoute.label}>
                              <NavSubItemLink to={childRoute.path}
                                              onClick={this.closeDesktopDropdown}>{childRoute.label}</NavSubItemLink>
                            </NavSubItem>
                          ))}
                        </NavSubItems>
                      </NavSubMenu>
                    </NavItem>
                  );
                })}
                <NavItemButton><ButtonLink to={routes.donate}>Donate</ButtonLink></NavItemButton>
                <NavItemButton><ButtonLink to={routes.contact}>Contact</ButtonLink></NavItemButton>
              </NavItems>
            </DesktopNavMenu>
            <CustomMobileNavMenu
              open={this.state.mobileNavMenuOpen}
              logo={logoReverse}
              linkClicked={this.toggleMobileNavMenu}
              socialLinks={socialLinks}
              contactEmail={contactEmail}
              contactPhone={contactPhone}
              siteName={siteName}
              sitemap={mobileSitemap}
            />
            <MobileNavMenuButton
              open={this.state.mobileNavMenuOpen}
              onClick={this.toggleMobileNavMenu}
            />
          </React.Fragment>
        )}
        <NavBarBackground/>
      </NavBarContainer>
    );
  }
}

export default NavBar;
