import { fonts } from './index';
import { css } from 'styled-components';
import { breakpoints } from '../layout';

export const typeT84sb = css`
  font-family: ${fonts.tiemposHeadlineSemibold.family};
  font-weight: ${fonts.tiemposHeadlineSemibold.weights.semibold};
  font-size: 84px;
  line-height: 84px;
`;

export const rtypeT84sb = css`
  font-family: ${fonts.tiemposHeadlineSemibold.family};
  font-weight: ${fonts.tiemposHeadlineSemibold.weights.semibold};
  font-size: 38px;
  line-height: 42px;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 66px;
    line-height: 66px;
  }

  @media(min-width: ${breakpoints.hg}) {
    font-size: 84px;
    line-height: 84px;
  }

`;

export const typeT38sb = css`
  font-family: ${fonts.tiemposHeadlineSemibold.family};
  font-weight: ${fonts.tiemposHeadlineSemibold.weights.semibold};
  font-size: 38px;
  line-height: 48px;
`;

export const rtypeT38sb = css`
  font-family: ${fonts.tiemposHeadlineSemibold.family};
  font-weight: ${fonts.tiemposHeadlineSemibold.weights.semibold};
  font-size: 21px;
  line-height: 30px;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 38px;
    line-height: 48px;
  }
`;

export const typeT21sb = css`
  font-family: ${fonts.tiemposHeadlineSemibold.family};
  font-weight: ${fonts.tiemposHeadlineSemibold.weights.semibold};
  font-size: 21px;
  line-height: 30px;
`;

export const rtypeT21sb = css`
  font-family: ${fonts.tiemposHeadlineSemibold.family};
  font-weight: ${fonts.tiemposHeadlineSemibold.weights.semibold};
  font-size: 16px;
  line-height: 24px;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 21px;
    line-height: 30px;
  }
`;

export const typeT16sb = css`
  font-family: ${fonts.tiemposHeadlineSemibold.family};
  font-weight: ${fonts.tiemposHeadlineSemibold.weights.semibold};
  font-size: 16px;
  line-height: 21px;
`;

export const typeN42r = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 42px;
  line-height: 40px;
`;

export const rtypeN38r = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 21px;
  line-height: 30px;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 38px;
    line-height: 40px;
  }
`;

export const typeN21bu = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.bold};
  font-size: 21px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const rtypeN21bu = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.bold};
  font-size: 16px;
  line-height: 1.4em;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 21px;
    line-height: 1.4em;
  }
`;

export const typeN21r = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 21px;
  line-height: 30px;
`;

export const rtypeN21b = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.bold};
  font-size: 16px;
  line-height: 24px;
  @media(min-width: ${breakpoints.lg}) {
    font-size: 21px;
    line-height: 30px;
  }
`;

export const typeN21ru = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 21px;
  line-height: 34px;
  letter-spacing: 3px;
`;

export const rtypeN18r = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 16px;
  line-height: 1.6em;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 18px;
  }
`;

export const typeN16r = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 16px;
  line-height: 24px;
`;

export const rtypeN16r = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 14px;
  line-height: 21px;

  @media(min-width: ${breakpoints.lg}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const typeN16ru = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

export const typeN16b = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.bold};
  font-size: 16px;
  line-height: 24px;
`;

export const typeN16bu = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.bold};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2.54px;
  text-transform: uppercase;
`;

export const typeN14r = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 14px;
  line-height: 21px;
`;

export const typeN14ru = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2.63px;
  text-transform: uppercase;
`;

export const typeN12r = css`
  font-family: ${fonts.national2.family};
  font-weight: ${fonts.national2.weights.regular};

  font-size: 12px;
  line-height: 18px;
`;
