import React from 'react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { rtypeN16r, rtypeT21sb, typeN12r, typeN16r, typeT16sb } from '../../styles/typography/presets';
import * as Yup from 'yup';
import { colors } from '../../styles/colors';
import { DarkBlueOutlineButton, PurpleOutlineButton } from '../buttons';
import { TextField } from '../forms';
import { webhooks } from '../../webhooks';

const SuccessMessage = styled.div`
  ${rtypeT21sb};
`;

const Footnote = styled.div`
  ${rtypeN16r};
  line-height: 24px;
  color: ${colors.grey};
`;

const SubmitButton = styled(DarkBlueOutlineButton)`

`;

const InlineFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
`;

const StyledField = styled(TextField)`
  min-width: 15px;
`;

const Part1 = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: auto 150px;
  grid-column-gap: 12px;
  
  ${StyledField} {
    grid-column: 1;
    grid-row: 1;
    justify-self: stretch;
  }
  
  ${SubmitButton} {
    grid-column: 2;
    grid-row: 1;
  }
  
  ${Footnote} {
    margin-top: 8px;
    grid-column: 1;
    grid-row: 2;
  }
`;

const Part2 = styled.div`
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fade-in 1s ease-out;
  display: grid;
  align-items: flex-end;
  grid-template-columns: auto 150px;
  grid-column-gap: 12px;
  
  ${InlineFields} {
    grid-column: 1;
    grid-row: 1;
    justify-self: stretch;
  }
  
  ${SubmitButton} {
    grid-column: 2;
    grid-row: 1;
  }
  
  ${Footnote} {
    margin-top: 8px;
    grid-column: 1;
    grid-row: 2;
  }
`;

const StyledForm = styled(Form)`
`;

const Container = styled.div`
  ${StyledField} {
    border-bottom: 1px solid ${props => props.white ? colors.white : colors.darkBlue};
    color: ${props => props.white ? colors.white : colors.darkBlue};
    
    &::placeholder {
      color: ${props => props.white ? colors.white : colors.darkBlue};
    }
  }
`;

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      part: 1,
      email: '',
    };
  }

  handleNext = (values, actions) => {
    actions.setSubmitting(true);
    fetch(this.props.webhook, {
      method: 'POST',
      body: JSON.stringify(values),
    }).then(response => {
      actions.setSubmitting(false);
    });
    this.setState({
      part: 2,
      email: values.email,
    });
  };

  handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    fetch(this.props.webhook, {
      method: 'POST',
      body: JSON.stringify({
        email: this.state.email,
        ...values,
      }),
    }).then(response => {
      actions.setSubmitting(false);
      this.setState({
        part: 3,
      });
    });
  };

  render() {
    return (
      <Container {...this.props}>
        {this.state.part === 1 && (
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required(),
            })}
            onSubmit={this.handleNext}
          >
            {({isValid}) => (
              <StyledForm>
                <Part1 style={this.state.part === 1 ? null : { display: 'none' }}>
                  <StyledField type={'email'} name={'email'} placeholder={'What\'s your email?'}/>
                  <SubmitButton disabled={!isValid} type={'submit'}>Next →</SubmitButton>
                  {this.props.footnote && (
                    <Footnote>{this.props.footnote}</Footnote>
                  )}
                </Part1>
              </StyledForm>
            )}
          </Formik>
        )}
        {this.state.part === 2 && (
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required(),
              lastName: Yup.string().required(),
            })}
            onSubmit={this.handleSubmit}
          >
            {({ isValid, isSubmitting }) => (
              <StyledForm>
                <Part2>
                  <InlineFields>
                    <StyledField autoFocus={true} type={'text'} name={'firstName'}
                                 placeholder={'First name'}/>
                    <StyledField type={'text'} name={'lastName'} placeholder={'Last name'}/>
                  </InlineFields>
                  {this.props.footnote && (
                    <Footnote>{this.props.footnote}</Footnote>
                  )}
                  <SubmitButton disabled={!isValid || isSubmitting} type={'submit'}>Submit</SubmitButton>
                </Part2>
              </StyledForm>
            )}
          </Formik>
        )}
        {this.state.part === 3 && (
          <SuccessMessage>Thanks! You'll be receiving updates from us.</SuccessMessage>
        )}
      </Container>
    );
  }
}

export default EmailForm;