import { css } from 'styled-components';
import tiemposHeadlineSemibold from '../../assets/fonts/TiemposHeadlineWeb-Semibold.woff';
import national2RegularItalic from '../../assets/fonts/National2Web-RegularItalic.woff';
import national2Regular from '../../assets/fonts/National2Web-Regular.woff';
import national2Bold from '../../assets/fonts/National2Web-Bold.woff';
import nationalBold from '../../assets/fonts/National-Bold.otf';
import nationalBook from '../../assets/fonts/National-Book.otf';


export const fontDisplay = 'block';

export const fontFamilyTiemposHeadlineSemibold = "'Tiempos Headline'";
export const fontFamilyNational2 = "'National 2'";
export const fontFamilyNational = "'National'";

export const fonts = {
  national2: {
    family: `${fontFamilyNational2}, Sans Serif`,
    weights: {
      regular: '300',
      bold: '400',
    }
  },
  national: {
    family: `${fontFamilyNational}, Sans Serif`,
    weight: {
      book: '300',
      bold: '400',
    }
  },
  tiemposHeadlineSemibold: {
    family: `${fontFamilyTiemposHeadlineSemibold}, Serif`,
    weights: {
      semibold: '300',
    }
  },
};


export const fontFaces = css`
  @font-face {
    src: url(${tiemposHeadlineSemibold});
    font-family: ${fontFamilyTiemposHeadlineSemibold};
    font-display: ${fontDisplay};
  }
  
  @font-face {
    src: url(${national2Regular});
    font-family: ${fontFamilyNational2};
    font-display: ${fontDisplay};
    font-weight: ${fonts.national2.weights.regular}
  }
  
  @font-face {
    src: url(${national2RegularItalic});
    font-family: ${fontFamilyNational2};
    font-display: ${fontDisplay};
    font-weight: ${fonts.national2.weights.regular};
    font-style: italic;
  }
  
  @font-face {
    src: url(${national2Bold});
    font-family: ${fontFamilyNational2};
    font-display: ${fontDisplay};
    font-weight: ${fonts.national2.weights.bold};
  }
  
  @font-face {
    src: url(${nationalBook});
    font-family: ${fontFamilyNational};
    font-display: ${fontDisplay};
    font-weight: ${fonts.national.weight.book};
  }
  
  @font-face {
    src: url(${nationalBold});
    font-family: ${fontFamilyNational};
    font-display: ${fontDisplay};
    font-weight: ${fonts.national.weight.bold};
  }
`;

